import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BlogPost from './BlogPost';
import { selectBlog } from '../../store/actions/blogActions';
import style from './blog.module.css';

const propTypes = {
	blogs: PropTypes.array.isRequired,
	selectBlog: PropTypes.func.isRequired
};

const Blog = (props) => {
	let blogs;
	if (props.blogs) {
		blogs = props.blogs.map((blog, i) => <BlogPost blog={blog} key={blog._id} selectBlog={props.selectBlog} index={i} />);
	}

	return (
		<main>
			<h2>Show Recaps</h2>
			<div className={style.blogs}>
				{blogs}
			</div>
		</main>
	);
};
Blog.propTypes = propTypes;

function mapStateToProps(state) {
	return {
		blogs: state.blogs.blogs || []
	};
}

function mapDispatchToProps(dispatch) {
	return {
		selectBlog: (blog) => {
			dispatch(selectBlog(blog));
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
