import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import PostsContainer from '../blog/PostsContainer';
import EpkEditor from '../dashboard/EpkEditor';

const propTypes = {
	details: PropTypes.array.isRequired,
	epk: PropTypes.object.isRequired,
	loggedIn: PropTypes.bool.isRequired,
	addDetail: PropTypes.func.isRequired,
	reorderDetails: PropTypes.func.isRequired
};

class Dashboard extends React.Component {
	componentDidMount() {
		if (!this.props.loggedIn) {
			this.props.history.push('/login');
		}
	}

	render() {
		const {
			epk,
			details,
			addDetail,
			reorderDetails
		} = this.props;

		return (
			<main className="w100 center">
				<div className="dashContent">
					<h2>Dashboard</h2>
					<div className="center">
						<button className="btn-home point"
							onClick={() => {
								this.props.history.push('/new');
							}}>
							New Blog
					</button>
					</div>
					<div className="center col">
						<PostsContainer />
					</div>
					<EpkEditor epk={epk}
						details={details}
						addDetail={addDetail}
						reorderDetails={reorderDetails} />
				</div>
			</main>
		);
	}
}
Dashboard.propTypes = propTypes;

export default withRouter(Dashboard);
