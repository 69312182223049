export const GET_DETAILS_REQUEST = 'GET_DETAILS_REQUEST';
export const GET_DETAILS_SUCCESS = 'GET_DETAILS_SUCCESS';
export const GET_DETAILS_FAILURE = 'GET_DETAILS_FAILURE';

export const ADD_DETAIL_REQUEST = 'ADD_DETAIL_REQUEST';
export const ADD_DETAIL_SUCCESS = 'ADD_DETAIL_SUCCESS';
export const ADD_DETAIL_FAILURE = 'ADD_DETAIL_FAILURE';

export const UPDATE_DETAIL_REQUEST = 'UPDATE_DETAIL_REQUEST';
export const UPDATE_DETAIL_SUCCESS = 'UPDATE_DETAIL_SUCCESS';
export const UPDATE_DETAIL_FAILURE = 'UPDATE_DETAIL_FAILURE';

export const REORDER_DETAILS_REQUEST = 'REORDER_DETAILS_REQUEST';
export const REORDER_DETAILS_SUCCESS = 'REORDER_DETAILS_SUCCESS';
export const REORDER_DETAILS_FAILURE = 'REORDER_DETAILS_FAILURE';

export const DELETE_DETAIL_REQUEST = 'DELETE_DETAIL_REQUEST';
export const DELETE_DETAIL_SUCCESS = 'DELETE_DETAIL_SUCCESS';
export const DELETE_DETAIL_FAILURE = 'DELETE_DETAIL_FAILURE';

export const getDetailsRequest = () => (
	{ type: GET_DETAILS_REQUEST }
);

export const getDetailsSuccess = (details) => (
	{ type: GET_DETAILS_SUCCESS, details }
);

export const getDetailsFailure = () => (
	{ type: GET_DETAILS_FAILURE }
);

export const addDetailRequest = () => (
	{ type: ADD_DETAIL_REQUEST }
);

export const addDetailSuccess = (newDetail) => (
	{ type: ADD_DETAIL_SUCCESS, newDetail }
);

export const addDetailFailure = () => (
	{ type: ADD_DETAIL_FAILURE }
);

export const updateDetailRequest = () => (
	{ type: UPDATE_DETAIL_REQUEST }
);

export const updateDetailSuccess = (detail) => (
	{ type: UPDATE_DETAIL_SUCCESS, detail }
);

export const updateDetailFailure = () => (
	{ type: UPDATE_DETAIL_FAILURE }
);

export const reorderDetailsRequest = () => (
	{ type: REORDER_DETAILS_REQUEST }
);

export const reorderDetailsSuccess = () => (
	{ type: REORDER_DETAILS_SUCCESS }
);

export const reorderDetailsFailure = () => (
	{ type: REORDER_DETAILS_FAILURE }
);

export const deleteDetailRequest = () => (
	{ type: DELETE_DETAIL_REQUEST }
);

export const deleteDetailSuccess = (detailId) => (
	{ type: DELETE_DETAIL_SUCCESS, detailId }
);

export const deleteDetailFailure = () => (
	{ type: GET_DETAILS_FAILURE }
);
