import React from 'react';
import { connect } from 'react-redux';
import style from './media.module.css';
import FlyerCarousel from './FlyerCarousel';

class Media extends React.Component {
	componentDidMount() {
		const script = document.createElement('script');
		script.src = '../javascripts/instaFeed.js';
		script.async = true;
		document.body.appendChild(script);
	}

	render() {
		if (this.props.epk) {

			let {
				firstVideo,
				secondVideo,
				thirdVideo,
				fourthVideo
			} = this.props.epk;

			return (
				<main>
					<h2>Media</h2>
					<div className={style.videoContainer}>
						<iframe className={style.video}
							src={firstVideo}
							frameBorder="0"
							title="video1"
							allowFullScreen></iframe>
						<iframe className={style.video}
							title="video2"
							src={secondVideo}
							frameBorder="0"
							allowFullScreen></iframe>
					</div>
					<div className={style.videoContainer}>
						<iframe className={style.video} title="video3"
							src={thirdVideo}
							frameBorder="0"
							allowFullScreen></iframe>
						<iframe className={style.video} title="video4"
							src={fourthVideo}
							frameBorder="0"
							allowFullScreen></iframe>
					</div>
					<div id="instafeed"></div>
					<h3>Show Flyers</h3>
					<FlyerCarousel />
				</main>
			);
		}
	}
}

function mapStateToProps(state) {
	return {
		epk: state.epk.epk || {}
	};
}

export default connect(mapStateToProps, null)(Media);
