import { updateObject, createReducer } from '../utilities';

import {
  GET_EPK_REQUEST,
  GET_EPK_SUCCESS,
  GET_EPK_FAILURE,
  UPDATE_EPK_REQUEST,
  UPDATE_EPK_SUCCESS,
  UPDATE_EPK_FAILURE
} from '../actions/epkActions';

const initialState = {
  epk: {}
};

const actionHandlers = {
  [GET_EPK_REQUEST]: state => (
    updateObject(state, {
      epk: state.epk,
      isFetching: true,
      error: false
    })
  ),

  [GET_EPK_SUCCESS]: (state, action) => (
    updateObject(state, {
      epk: action.epk,
      isFetching: false,
      error: false
    })
  ),

  [GET_EPK_FAILURE]: state =>
    updateObject(state, {
      epk: state.epk,
      isFetching: false,
      error: true
    }),

  [UPDATE_EPK_REQUEST]: state => (
    updateObject(state, {
      epk: state.epk,
      isFetching: true,
      error: false
    })
  ),

  [UPDATE_EPK_SUCCESS]: (state, action) => (
    updateObject(state, {
      epk: action.epk,
      isFetching: false,
      error: false
    })
  ),

  [UPDATE_EPK_FAILURE]: state =>
    updateObject(state, {
      epk: state.epk,
      isFetching: false,
      error: true
    }),
};

export default createReducer(initialState, actionHandlers);
