import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import style from './blog.module.css';

const propTypes = {
	blog: PropTypes.object.isRequired,
	loggedIn: PropTypes.bool.isRequired,
	selectBlog: PropTypes.func.isRequired,
	updateBlog: PropTypes.func.isRequired,
};

class EditBlog extends React.PureComponent {
	constructor(props) {
		super(props);

		const { date, title, image, setList, setLink, location, description, notes, lineup } = props.blog;
		this.state = {
			date,
			title,
			image,
			setList,
			setLink,
			location,
			description,
			notes,
			lineup,
			upload: null
		};
	}

	componentDidMount() {
		const { loggedIn, history } = this.props;
		if (!loggedIn) {
			history.push('/login');
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.blog._id) {
			this.setState({
				_id: nextProps.blog._id,
				date: nextProps.blog.date,
				title: nextProps.blog.title,
				notes: nextProps.blog.notes,
				lineup: nextProps.blog.lineup,
				image: nextProps.blog.image,
				setList: nextProps.blog.setList,
				setLink: nextProps.blog.setLink,
				location: nextProps.blog.location,
				description: nextProps.blog.description,
				upload: null
			});
		}
	}

	onDrop = (files) => {
		this.setState({
			image: files[0].preview,
			upload: files[0]
		});
	}

	goToBlog = () => {
		this.props.history.push('/blog');
	}

	render() {
		const {
			date,
			image,
			title,
			notes,
			lineup,
			setList,
			setLink,
			location,
			description
		} = this.state;

		return (
			<main>
				<h2>Blog Form</h2>
				<form className="col">
					<img src={image}
						alt="editBlog"
						className="editBlogImage" />
					<input type="text"
						placeholder="Title"
						value={title}
						onChange={(evt) => { this.setState({ title: evt.target.value }); }} />
					<input type="text"
						placeholder="Location"
						value={location}
						onChange={(evt) => { this.setState({ location: evt.target.value }); }} />
					<input type="text"
						placeholder="Date"
						value={date}
						onChange={(evt) => { this.setState({ date: evt.target.value }); }} />
					<textarea className="textarea input-field"
						placeholder="Set List"
						value={setList}
						onChange={(evt) => { this.setState({ setList: evt.target.value }); }} />
					<input type="text"
						placeholder="Set Link"
						value={setLink}
						onChange={(evt) => { this.setState({ setLink: evt.target.value }); }} />
					<textarea placeholder="Set Description"
						value={description}
						onChange={(evt) => { this.setState({ description: evt.target.value }); }} />
					<input type="text"
						placeholder="Set List Notes"
						value={notes}
						onChange={(evt) => { this.setState({ notes: evt.target.value }); }} />
					<input type="text"
						placeholder="Lineup"
						value={lineup}
						onChange={(evt) => { this.setState({ lineup: evt.target.value }); }} />
					<div className={style.panel}>
						<h2>File Uploader</h2>
						<Dropzone onDrop={(files) => { this.onDrop(files); }}>
							<img src={this.state.image}
								alt="uploadImage"
								className="w100 point" />
						</Dropzone>
					</div>
					<button onClick={() => {
						this.props.updateBlog(this.state);
						this.props.history.push('/dashboard');
					}}
						type="button"
						value="Submit"
						className="btn btn-contact">Submit
				</button>
				</form>
			</main>
		);
	}
}
EditBlog.propTypes = propTypes;

export default withRouter(EditBlog)
