import React from 'react';
import style from './bandsInTown.module.css';

class BandsInTown extends React.PureComponent {
	componentDidMount() {
		const script = document.createElement('script');
		script.src = 'https://widget.bandsintown.com/main.min.js';
		script.async = true;
		document.body.appendChild(script);
	}

	render() {
		return (
			<div className={`${style.tour} mLA`}>
				<a className="bit-widget-initializer" data-artist-name="Collidoscope" data-display-local-dates="false" data-display-past-dates="true" data-auto-style="false" data-text-color="#2F95DE" data-link-color="#00C33C" data-popup-background-color="#000000" data-background-color="#000000" data-display-limit="15" data-link-text-color="#000000"></a>
			</div>
		);
	}
}

export default BandsInTown;
