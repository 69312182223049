import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import AppContainer from './components/common';
import configureStore from './store';

ReactDOM.render(
	<Provider store={configureStore()}>
		<Router>
			<AppContainer />
		</Router>
	</Provider>,
	document.getElementById('root')
);
serviceWorker.unregister();
