import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import style from './media.module.css';
import Images from '../../assets/images';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const FlyerCarousel = () => (
	<Carousel
		showArrows={true}
		// autoPlay={true}
		infiniteLoop={true}
		showStatus={false}
		showIndicators={true}
		className={style.flyerCarousel}>
		<div className={style.flyerPic}>
			<img src={Images.squab1} alt="squab1" />
		</div>
		<div className={style.flyerPic}>
			<img src={Images.madSquab} alt="madSquab" />
		</div>
		<div className={style.flyerPic}>
			<img src={Images.opiuo} alt="opiuo" />
		</div>
		<div className={style.flyerPic}>
			<img src={Images.theEldo} alt="the eldo" />
		</div>
		<div className={style.flyerPic}>
			<img src={Images.pearls1} alt="pearls1" />
		</div>
		<div className={style.flyerPic}>
			<img src={Images.cervantes2} alt="cervs2" />
		</div>
		<div className={style.flyerPic}>
			<img src={Images.cervantes1} alt="cervs1" />
		</div>
		<div className={style.flyerPic}>
			<img src={Images.exmag} alt="exmag" />
		</div>
		<div className={style.flyerPic}>
			<img src={Images.supervision} alt="supervision" />
		</div>
		<div className={style.flyerPic}>
			<img src={Images.krookedDrivers} alt="krookedDrivers" />
		</div>
		<div className={style.flyerPic}>
			<img src={Images.junoWhat} alt="junoWhat" />
		</div>
		<div className={style.flyerPic}>
			<img src={Images.spreadTheWordFest} alt="spreadTheWordFest" />
		</div>
		<div className={style.flyerPic}>
			<img src={Images.headHills} alt="headHills" />
		</div>
		<div className={style.flyerPic}>
			<img src={Images.euphoria} alt="euphoria" />
		</div>
	</Carousel>
);

export default FlyerCarousel;
