import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HomeContainer from '../home';
import Contact from '../contact';
import About from '../about';
import Media from '../media';
import Music from '../music';
import EpkContainer from '../epk';
import DashboardContainer from '../dashboard';
import Login from '../login';
import Tour from '../tour';
import Blog from '../blog';
import NewBlogContainer from '../blog/NewBlogContainer';
import SingleBlog from '../blog/SingleBlog';
import EditBlogContainer from '../blog/EditBlogContainer';

const Routes = () => (
	<Switch>
		<Route exact path="/" component={HomeContainer} />
		<Route exact path="/contact" component={Contact} />
		<Route exact path="/about" component={About} />
		<Route exact path="/media" component={Media} />
		<Route exact path="/music" component={Music} />
		<Route exact path="/tour" component={Tour} />
		<Route exact path="/epk" component={EpkContainer} />
		<Route exact path="/dashboard" component={DashboardContainer} />
		<Route exact path="/login" component={Login} />
		<Route exact path="/blog" component={Blog} />
		<Route exact path="/new" component={NewBlogContainer} />
		<Route exact path="/blog/:id" component={SingleBlog} />
		<Route exact path="/edit/:id" component={EditBlogContainer} />

	</Switch>
);

export default Routes;
