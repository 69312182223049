import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectBlog } from '../../store/actions/blogActions';
import { blogSelector, blogsSelector } from '../../store/selectors/blogSelectors';
import style from './blog.module.css';

const propTypes = {
	blog: PropTypes.object,
	selectBlog: PropTypes.func.isRequired
};

const defaultProps = {
	blog: {}
}

class SingleBlog extends React.PureComponent {
	componentDidMount() {
		const blogId = window.location.pathname.split('/')[2]
		this.props.selectBlog(blogId);
	}

	render() {
		const {
			date,
			image,
			title,
			notes,
			lineup,
			location,
			setList,
			setLink,
			description
		} = this.props.blog;

		return (
			<main>
				<div className={style.singlePost}>
					<img src={image} className="w100" alt="blog pic" />
					<div className={style.content}>
						<p className={style.title}>{title}</p>
						<div className={style.blogLocationContainer}>
							<p className={style.locDate}>{moment(date).format('MM DD YYYY')}</p>
							<p className={style.locDate}>{location}</p>
						</div>
						<p className={style.title}>{lineup}</p>
						<p className={style.setTitle}>Set List</p>
						<p className={style.blogItem}>{setList}</p>
						<p className="setTitle">Description</p>
						<p className={style.blogItem}>{description}</p>
						<p className={style.blogItem}>{notes}</p>
						<a href={setLink}><p>Listen</p></a>
					</div>
				</div>
			</main>
		);
	}
}
SingleBlog.propTypes = propTypes;
SingleBlog.defaultProps = defaultProps;

function mapStateToProps(state) {
	const blogs = blogsSelector(state);
	return {
		blogs,
		blog: blogSelector(state)
	}
}

function mapDispatchToProps(dispatch) {
	return {
		selectBlog: (id) => {
			dispatch(selectBlog(id));
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleBlog);
