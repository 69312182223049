import React from 'react';
import style from './music.module.css';

const Music = () => (
	<main>
		<h2>Music</h2>
		<div className={`${style.musicConten} col mLA`}>
			<div className={style.musicRow}>
				<section className="col mLA">
					<h3>Albums</h3>
					<iframe width="100%" scrolling="no" frameBorder="no"
						title="albums"
						className={style.musicIframe}
						src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/320149710&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true"></iframe>
				</section>
				<section className="col mLA">
					<h3>Singles</h3>
					<iframe width="100%" scrolling="no" frameBorder="no"
						title="singles"
						className={style.musicIframe}
						src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/320149228&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true"></iframe>
				</section>
			</div>
			<div className={style.musicRow}>
				<section className="col mLA">
					<h3>Remixes</h3>
					<iframe width="100%" scrolling="no" frameBorder="no"
						title="remixes"
						className={style.musicIframe}
						src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/320147701&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true"></iframe>
				</section>
				<section className="col mLA">
					<h3>Live Sets</h3>
					<iframe width="100%" scrolling="no" frameBorder="no"
						title="live sets"
						className={style.musicIframe}
						src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/320147123&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true"></iframe>
				</section>
			</div>
		</div>
	</main>
);
export default Music;
