import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
	addDetail: PropTypes.func.isRequired
};

class NewDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			values: {
				what: '',
				where: '',
				type: 'Type',
			},
			style: {
				display: 'none'
			},
			opsOpen: false,
		}
	}

	toggleOps = () => {
		if (this.state.opsOpen) {
			this.setState({
				style: {
					display: 'none'
				},
				opsOpen: false
			})
		}
		else {
			this.setState({
				style: {
					display: 'flex'
				},
				opsOpen: true
			})
		}
	}

	render() {
		return (
			<div className="newDetail row center">
				<input type="text"
					className="input addDetail"
					placeholder="What?"
					value={this.state.values.what}
					onChange={(evt) => {
						this.setState({
							values: {
								what: evt.target.value,
								where: this.state.values.where,
								type: this.state.values.type
							}
						});
					}}
				/>
				<input
					type="text"
					className="input addDetail"
					placeholder="Where?"
					value={this.state.values.where}
					onChange={(evt) => {
						this.setState({
							values: {
								what: this.state.values.what,
								where: evt.target.value,
								type: this.state.values.type
							}
						});
					}}
				/>
				<div className="row">
					<div className="col addDetail">
						<div
							onClick={() => {
								this.toggleOps();
							}}
							className="rel detOp point center">{this.state.values.type}</div>
						<div style={this.state.style} className="col abs opBox">
							<div
								className="op point center"
								onClick={() => {
									this.toggleOps();
									this.setState({
										values: {
											what: this.state.values.what,
											where: this.state.values.where,
											type: 'notable'
										}
									});
								}}
							>Notable Performance</div>
							<div
								className="op point center"
								onClick={() => {
									this.toggleOps();
									this.setState({
										values: {
											what: this.state.values.what,
											where: this.state.values.where,
											type: 'headline'
										}
									});
								}}
							>Headline Performance</div>
							<div
								className="op point center"
								onClick={() => {
									this.toggleOps();
									this.setState({
										values: {
											what: this.state.values.what,
											where: this.state.values.where,
											type: 'festival'
										}
									});
								}}
							>Festival Performance</div>
						</div>
					</div>
					<div
						onClick={() => {
							this.setState({
								values: {
									what: '',
									where: '',
									type: 'type'
								}
							});
							this.props.addDetail(this.state.values);
						}}
						className="penBox center point mTB">
						<i className="fa fa-2x fa-plus-square" aria-hidden="true"></i>
					</div>
				</div>
			</div>
		);
	}
}
NewDetail.propTypes = propTypes;



export default NewDetail;
