import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import NewDetail from './NewDetail';
import Details from './Details';
import Iframes from './Iframes';

const propTypes = {
	details: PropTypes.array.isRequired,
	epk: PropTypes.object.isRequired,
	addDetail: PropTypes.func.isRequired,
	reorderDetails: PropTypes.func.isRequired
};

class Epk extends React.Component {
	static getDerivedStateFromProps(newProps, state) {
		if (newProps.details.length > 0) {
			const sortedNotableDetails = newProps.details.filter((detail) => {
				return (detail.type === 'notable');
			});

			const sortedHeadlineDetails = newProps.details.filter((detail) => {
				return (detail.type === 'headline');
			});

			const sortedFestivalDetails = newProps.details.filter((detail) => {
				return (detail.type === 'festival');
			});

			const newState = {
				notableDetails: sortedNotableDetails.sort((a, b) => a.index - b.index),
				headlineDetails: sortedHeadlineDetails.sort((a, b) => a.index - b.index),
				festivalDetails: sortedFestivalDetails.sort((a, b) => a.index - b.index)
			}
			return newState;
		} else {
			return null;
		}
	}

	constructor(props) {
		super(props);
		if (props.details.length > 0) {
			const sortedNotableDetails = props.details.filter((detail) => {
				return (detail.type === 'notable');
			});

			const sortedHeadlineDetails = props.details.filter((detail) => {
				return (detail.type === 'headline');
			});

			const sortedFestivalDetails = props.details.filter((detail) => {
				return (detail.type === 'festival');
			});

			this.state = {
				notableDetails: sortedNotableDetails.sort((a, b) => b.index - a.index),
				headlineDetails: sortedHeadlineDetails.sort((a, b) => b.index - a.index),
				festivalDetails: sortedFestivalDetails.sort((a, b) => b.index - a.index)
			}
		} else {
			this.state = {
				notableDetails: [],
				headlineDetails: [],
				festivalDetails: []
			}
		}
	}

	reorderDetails = (details, startIndex, endIndex) => {
		const result = Array.from(details);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		let updatedDetails = [];

		result.forEach((detail, i) => {
			detail.index = i;
			updatedDetails.push(detail);
		});
		return updatedDetails;
	}

	onDragEnd = (result) => {
		const { droppableId, index } = result.destination;
		const { reorderDetails } = this.props;
		const startIndex = result.source.index;

		if (!result.destination) {
			return;
		}

		if (droppableId === 'notableDroppable') {
			const updatedDetails = this.reorderDetails(this.state.notableDetails, startIndex, index);

			this.setState({ notableDetails: updatedDetails });
			reorderDetails(updatedDetails);
			return;
		} else if (droppableId === 'headlineDroppable') {
			const updatedDetails = this.reorderDetails(this.state.headlineDetails, startIndex, index);

			this.setState({ headlineDetails: updatedDetails });
			reorderDetails(updatedDetails);
			return;
		} else {
			const updatedDetails = this.reorderDetails(this.state.festivalDetails, startIndex, index);

			this.setState({ festivalDetails: updatedDetails });
			reorderDetails(updatedDetails);
			return;
		}
	};

	addDetail = (newDetail) => {
		const { type } = newDetail;
		const { notableDetails, headlineDetails, festivalDetails } = this.state;

		if (type === 'notable') {
			newDetail.index = notableDetails.length;
		} else if (type === 'headline') {
			newDetail.index = headlineDetails.length;
		} else {
			newDetail.index = festivalDetails.length;
		}
		this.props.addDetail(newDetail);
	}

	render() {
		const { details, epk } = this.props;
		let notableDetails;
		let headlineDetails;
		let festivalDetails;

		if (details.length > 0) {
			notableDetails = (
				<Droppable droppableId="notableDroppable">
					{(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							style={{
								backgroundColor: 'transparent',
								width: '100%'
							}}
							{...provided.droppableProps}
						><Details title={'Notable Performances'} details={this.state.notableDetails} />
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			);

			headlineDetails = (
				<Droppable droppableId="headlineDroppable">
					{(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							style={{
								backgroundColor: 'transparent',
								width: '100%'
							}}
							{...provided.droppableProps}
						><Details title={'Headline Performances'} details={this.state.headlineDetails} />{provided.placeholder}
						</div>
					)}
				</Droppable>
			);

			festivalDetails = (
				<Droppable droppableId="festivalDroppable">
					{(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							style={{
								backgroundColor: 'transparent',
								width: '100%'
							}}
							{...provided.droppableProps}
						><Details title={'Festival Appearances'} details={this.state.festivalDetails} />{provided.placeholder}
						</div>
					)}
				</Droppable>
			);
		}

		return (
			<div className="center col">
				<h3>EPK</h3>
				<NewDetail addDetail={this.addDetail} />
				<DragDropContext
					onDragEnd={this.onDragEnd}
				>
					{notableDetails}
					{headlineDetails}
					{festivalDetails}
				</DragDropContext>
				<Iframes epk={epk} />
			</div>
		);
	}
};
Epk.propTypes = propTypes;

export default Epk;
