import React from 'react';
import style from './bookingInfo.module.css';

const BookingInfo = () =>
	<div className={`${style.booking} col center`}>
		<p className={style.bookingHeader}>Booking</p>
		<p className={style.bookingText}>Hank Brigham</p>
		<p className={style.bookingText}>Cell: (512) 656-5619</p>
		<p className={style.bookingText}>collidoscopemusic@gmail.com</p>
	</div>;

export default BookingInfo;
