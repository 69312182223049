export const GET_EPK_REQUEST = 'GET_EPK_REQUEST';
export const GET_EPK_SUCCESS = 'GET_EPK_SUCCESS';
export const GET_EPK_FAILURE = 'GET_EPK_FAILURE';
export const UPDATE_EPK_REQUEST = 'UPDATE_EPK_REQUEST';
export const UPDATE_EPK_SUCCESS = 'UPDATE_EPK_SUCCESS';
export const UPDATE_EPK_FAILURE = 'UPDATE_EPK_FAILURE';

export const getEpkRequest = () => (
  { type: GET_EPK_REQUEST }
);

export const getEpkSuccess = (epk) => (
  { type: GET_EPK_SUCCESS, epk }
);

export const getEpkFailure = () => (
  { type: GET_EPK_FAILURE }
);

export const updateEpkRequest = () => (
  { type: UPDATE_EPK_REQUEST }
);

export const updateEpkSuccess = (epk) => (
  { type: UPDATE_EPK_SUCCESS, epk }
);

export const updateEpkFailure = () => (
  { type: UPDATE_EPK_FAILURE }
);
