import { updateObject, createReducer } from '../utilities';

import {
	LOGIN_SUCCESS,
	GET_BLOGS_REQUEST,
	GET_BLOGS_SUCCESS,
	GET_BLOGS_FAILURE,
	ADD_BLOG_REQUEST,
	ADD_BLOG_SUCCESS,
	ADD_BLOG_FAILURE,
	UPDATE_BLOG_REQUEST,
	UPDATE_BLOG_SUCCESS,
	UPDATE_BLOG_FAILURE,
	DELETE_BLOG_REQUEST,
	DELETE_BLOG_SUCCESS,
	DELETE_BLOG_FAILURE,
	SELECT_BLOG,
	LOGOUT
} from '../actions/blogActions';

const initialState = {
	loggedIn: false,
	blogs: [],
	isFetching: false,
	error: false,
	selectedBlogId: ''
};

const actionHandlers = {
	[GET_BLOGS_REQUEST]: state => (
		updateObject(state, {
			blogs: state.blogs,
			isFetching: true,
			error: false,
			...state
		})
	),

	[GET_BLOGS_SUCCESS]: (state, action) => updateObject(state, {
			blogs: action.blogs,
			isFetching: false,
			error: false
		}),

	[GET_BLOGS_FAILURE]: state =>
		updateObject(state, {
			blogs: state.blogs,
			isFetching: false,
			error: true
		}),

	[ADD_BLOG_REQUEST]: state => (
		updateObject(state, {
			...state
		})
	),

	[ADD_BLOG_SUCCESS]: (state, action) => (
		updateObject(state, {
			blogs: state.blogs.concat([action.blog]),
			isFetching: false,
			error: false
		})
	),

	[ADD_BLOG_FAILURE]: state =>
		updateObject(state, {
			blogs: state.blogs,
			isFetching: false,
			error: true
		}),

	[UPDATE_BLOG_REQUEST]: state => (
		updateObject(state, {
			...state
		})
	),

	[UPDATE_BLOG_SUCCESS]: (state, action) => {
		const updatedBlog = action.blog;
		const oldIndex = state.blogs.findIndex((blog) => {
			return blog._id === updatedBlog._id;
		});

		let updatedBlogs = state.blogs.slice();
		updatedBlogs.splice(oldIndex, 1, updatedBlog);

		return updateObject(state, {
			blogs: updatedBlogs,
			isFetching: false,
			error: false
		});
	},

	[UPDATE_BLOG_FAILURE]: state =>
		updateObject(state, {
			blogs: state.blogs,
			isFetching: false,
			error: true
		}),

	[DELETE_BLOG_REQUEST]: state => (
		updateObject(state, {
			...state
		})
	),

	[DELETE_BLOG_SUCCESS]: (state, action) => (
		updateObject(state, {
			blogs: state.blogs.filter(({ _id }) => _id !== action.blogId),
			isFetching: false,
			error: false
		})
	),

	[DELETE_BLOG_FAILURE]: state =>
		updateObject(state, {
			blogs: state.blogs,
			isFetching: false,
			error: true
		}),

	[SELECT_BLOG]: (state, action) =>
		updateObject(state, {
			...state,
			selectedBlogId: action.blogId
		}),
		
	[LOGIN_SUCCESS]: state =>
		updateObject(state, {
			loggedIn: true
		}),

	[LOGOUT]: state =>
		updateObject(state, {
			blogs: state.blogs,
			isFetching: false,
			error: false,
			loggedIn: false
		}),
};

export default createReducer(initialState, actionHandlers);
