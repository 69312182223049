import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
	image: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	isMobile: PropTypes.bool.isRequired
};

const ContentItem = props => {
	const { image, title, isMobile } = props;

	const bannerStyle = {
		backgroundImage: `url(${image})`,
		backgroundAttachment: 'fixed',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100% 900px',
		display: 'flex',
		justifyContent: 'center',
  	alignItems: 'center',
		width: '100%',
	};

	const mobileBannerStyle = {
		backgroundImage: `url(${image})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		display: 'flex',
		justifyContent: 'center',
  	alignItems: 'center',
		margin: 0,
		width: '100%',
		height: '45vh'
	};

	let markup = (
		<div className="homePic" style={ bannerStyle }>
			<h1 className="homeHead">{title}</h1>
		</div>
	)

	if (isMobile) {
		markup = (
			<div style={ mobileBannerStyle }>
        <h1 className="homeHead">{title}</h1>
      </div>
		)
	}

  return (
		<Fragment>
			{markup}
		</Fragment>
  );
};
ContentItem.propTypes = propTypes;

export default ContentItem;

