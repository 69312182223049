import React from 'react';
import { Link } from 'react-router-dom';
import style from './footer.module.css';

const Footer = () =>
	<footer className="center">
		<div className={`${style.socials} center mRA`}>
			<a href="https://www.facebook.com/collidoscopemusic/" className={style.socialLink}>
				<i className={`fa fa-facebook fa-2x point ${style.icon}`} aria-hidden="true"></i>
			</a>
			<a href="https://www.collidoscope-music.com/" className={style.socialLink}>
				<i className={`fa fa-spotify fa-2x point ${style.icon}`} aria-hidden="true"></i>
			</a>
			<a href="https://www.instagram.com/collidoscopemusic/" className={style.socialLink}>
				<i className={`fa fa-instagram fa-2x point ${style.icon}`} aria-hidden="true"></i>
			</a>
			<a href="https://www.soundcloud.com/collidoscopemusic/" className={style.socialLink}>
				<i className={`fa fa-soundcloud fa-2x point ${style.icon}`} aria-hidden="true"></i>
			</a>
			<a href="https://collidoscopemusic.bandcamp.com/releases" className={style.socialLink}>
				<i className={`fa fa-bandcamp fa-2x point ${style.icon}`} aria-hidden="true"></i>
			</a>
			<Link to="/epk" className={`${style.socialLink} point`}
				href="/epk">
				<i className={`fa fa-superpowers fa-2x point ${style.icon}`} aria-hidden="true"></i>
			</Link>
		</div>
	</footer>;

export default Footer;
