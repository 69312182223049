import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateEPK } from '../../store/thunks/epkThunks';
import style from './iframe.module.css';

const propTypes = {
	epk: PropTypes.object.isRequired,
	updateEPK: PropTypes.func.isRequired
};

class Iframes extends React.PureComponent {
	constructor(props) {
		super(props);

		const { _id,
			bio,
			album,
			firstSong,
			secondSong,
			thirdSong,
			firstVideo,
			secondVideo,
			thirdVideo,
			fourthVideo } = props.epk;

		this.state = {
			_id,
			bio,
			album,
			firstSong,
			secondSong,
			thirdSong,
			firstVideo,
			secondVideo,
			thirdVideo,
			fourthVideo,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.epk._id) {
			const {
				_id,
				bio,
				album,
				firstSong,
				secondSong,
				thirdSong,
				firstVideo,
				secondVideo,
				thirdVideo,
				fourthVideo
			} = nextProps.epk;

			this.setState({
				_id,
				bio,
				firstSong,
				secondSong,
				thirdSong,
				firstVideo,
				secondVideo,
				thirdVideo,
				fourthVideo,
				album
			});
		}
	}

	sendIt = (evt) => {
		evt.preventDefault();
		this.props.updateEPK(this.state);
	}

	render() {
		if (this.props.epk) {
			let {
				bio,
				album,
				firstSong,
				secondSong,
				thirdSong,
				firstVideo,
				secondVideo,
				thirdVideo,
				fourthVideo
			} = this.state;

			return (
				<div className="center col w100">
					<form onSubmit={this.sendIt} className="w100">
						<h4>Bio</h4>
						<textarea
							className={style.iFrame}
							onChange={(evt) => {
								this.setState({
									bio: evt.target.value,
								});
							}}
							value={bio} />
						<h4>First Song</h4>
						<textarea
							className={style.iFrame}
							onChange={(evt) => {
								this.setState({
									firstSong: evt.target.value,
								});
							}}
							value={firstSong} />
						<h4>Second Song</h4>
						<textarea
							className={style.iFrame}
							onChange={(evt) => {
								this.setState({
									secondSong: evt.target.value,
								});
							}}
							value={secondSong} />
						<h4>Third Song</h4>
						<textarea
							className={style.iFrame}
							onChange={(evt) => {
								this.setState({
									thirdSong: evt.target.value,
								});
							}}
							value={thirdSong} />
						<h4>First Video</h4>
						<textarea
							className={style.iFrame}
							onChange={(evt) => {
								this.setState({
									firstVideo: evt.target.value,
								});
							}}
							value={firstVideo} />
						<h4>Second Video</h4>
						<textarea
							className={style.iFrame}
							onChange={(evt) => {
								this.setState({
									secondVideo: evt.target.value,
								});
							}}
							value={secondVideo} />
						<h4>Third Video</h4>
						<textarea
							className={style.iFrame}
							onChange={(evt) => {
								this.setState({
									thirdVideo: evt.target.value,
								});
							}}
							value={thirdVideo} />
						<h4>Fourth Video</h4>
						<textarea
							className={style.iFrame}
							onChange={(evt) => {
								this.setState({
									fourthVideo: evt.target.value,
								});
							}}
							value={fourthVideo} />
						<h4>Album</h4>
						<textarea
							className={style.iFrame}
							onChange={(evt) => {
								this.setState({
									album: evt.target.value,
								});
							}}
							value={album} />
						<div className="row center mT20">
							<button type="submit"
								className="btn-home mS10"
							>Save</button>
							<button
								type="button"
								onClick={() => {
									this.setState({
										_id: this.props.epk._id,
										bio: this.props.epk.bio,
										firstSong: this.props.epk.firstSong,
										secondSong: this.props.epk.secondSong,
										thirdSong: this.props.epk.thirdSong,
										firstVideo: this.props.epk.firstVideo,
										secondVideo: this.props.epk.secondVideo,
										thirdVideo: this.props.epk.thirdVideo,
										fourthVideo: this.props.epk.fourthVideo,
										album: this.props.epk.album,
									});
								}}
								className="btn-home mS10"
							>Cancel</button>
						</div>
					</form>
				</div>
			);
		}
	}
}
Iframes.propTypes = propTypes;

function mapStateToProps(state) {
	return {
		epk: state.epk.epk || {}
	};
}

function mapDispatchToProps(dispatch) {
	return {
		updateEPK: (epk) => {
			dispatch(updateEPK(epk));
		}
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(Iframes);
