import { updateObject, createReducer } from '../utilities';

import {
	GET_DETAILS_REQUEST,
	GET_DETAILS_SUCCESS,
	GET_DETAILS_FAILURE,
	ADD_DETAIL_REQUEST,
	ADD_DETAIL_SUCCESS,
	ADD_DETAIL_FAILURE,
	UPDATE_DETAIL_REQUEST,
	UPDATE_DETAIL_SUCCESS,
	UPDATE_DETAIL_FAILURE,
	REORDER_DETAILS_REQUEST,
	REORDER_DETAILS_SUCCESS,
	REORDER_DETAILS_FAILURE,
	DELETE_DETAIL_REQUEST,
	DELETE_DETAIL_SUCCESS,
	DELETE_DETAIL_FAILURE
} from '../actions/detailActions';

const initialState = {
	details: [],
	isFetching: false,
	error: false
};

const actionHandlers = {
	[GET_DETAILS_REQUEST]: state => (
		updateObject(state, {
			details: state.details,
			isFetching: true,
			error: false
		})
	),

	[GET_DETAILS_SUCCESS]: (state, action) => (
		updateObject(state, {
			details: action.details,
			isFetching: false,
			error: false
		})
	),

	[GET_DETAILS_FAILURE]: state =>
		updateObject(state, {
			details: state.details,
			isFetching: false,
			error: true
		}),

	[ADD_DETAIL_REQUEST]: state => (
		updateObject(state, {
			...state
		})
	),

	[ADD_DETAIL_SUCCESS]: (state, action) =>
		updateObject(state, {
			details: state.details.concat([action.newDetail]),
			isFetching: false,
			error: false
		}),

	[ADD_DETAIL_FAILURE]: state =>
		updateObject(state, {
			details: state.details,
			isFetching: false,
			error: true
		}),

	[UPDATE_DETAIL_REQUEST]: state => (
		updateObject(state, {
			...state
		})
	),

	[UPDATE_DETAIL_SUCCESS]: (state, action) => {
		const updatedDetail = action.detail;
		const oldIndex = state.details.findIndex((detail) => {
			return detail._id === updatedDetail._id;
		});

		let updatedDetails = state.details.slice();
		updatedDetails.splice(oldIndex, 1, updatedDetail);

		return updateObject(state, {
			details: updatedDetails,
			isFetching: false,
			error: false
		});
	},

	[UPDATE_DETAIL_FAILURE]: state =>
		updateObject(state, {
			details: state.details,
			isFetching: false,
			error: true
		}),

	[REORDER_DETAILS_REQUEST]: state =>
		updateObject(state, {
			...state
		}),

	[REORDER_DETAILS_SUCCESS]: (state) =>
		updateObject(state, {
			...state
		}),

	[REORDER_DETAILS_FAILURE]: state =>
		updateObject(state, {
			...state,
			error: true
		}),

	[DELETE_DETAIL_REQUEST]: state => (
		updateObject(state, {
			...state
		})
	),

	[DELETE_DETAIL_SUCCESS]: (state, action) => (
		updateObject(state, {
			details: state.details.filter(({ _id }) => _id !== action.detailId),
			isFetching: false,
			error: false
		})
	),

	[DELETE_DETAIL_FAILURE]: state =>
		updateObject(state, {
			details: state.details,
			isFetching: false,
			error: true
		}),
};

export default createReducer(initialState, actionHandlers);
