import React from 'react';
import PropTypes from 'prop-types';
import BlogItem from './BlogItem';
import style from './blog.module.css';

const propTypes = {
	blogs: PropTypes.array.isRequired,
	selectBlog: PropTypes.func.isRequired,
	deleteBlog: PropTypes.func.isRequired
};

const Posts = (props) => {
	let markup;

	if (props.blogs) {
		const blogs = props.blogs.map((blog, i) => <BlogItem blog={blog}
			key={blog._id}
			selectBlog={props.selectBlog}
			deleteBlog={props.deleteBlog} />
		);

		markup = (
			<main>
				<h2>Blogs</h2>
				<div className={`w100 ${style.blogContainer} col`}>{blogs}
				</div>
			</main>
		);
	}

	return markup;
}
Posts.propTypes = propTypes;

export default Posts;
