import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import style from './blog.module.css';

const propTypes = {
	blog: PropTypes.object.isRequired,
	selectBlog: PropTypes.func.isRequired
};

const BlogPost = (props) => {
	const {
		date,
		image,
		title,
		notes,
		lineup,
		setLink,
		setList,
		location,
		description
	} = props.blog;

	return (
		<div className={`${style.mainPost} col`}
			onClick={() => {
				props.selectBlog(props.blog._id);
				props.history.push(`/blog/${props.blog._id}`);
			}}>
			<img src={image}
				alt="blog"
				className="w100" />
			<div className={style.content}>
				<p className={style.title}>{title}</p>
				<div className={style.locationDateContainer}>
					<p className={style.locDate}>{moment(date).format('MM DD YYYY')}</p>
					<p className={style.locDate}>{location}</p>
				</div>
				<p className={style.title}>{lineup}</p>
				<p className={style.setTitle}>Set List</p>
				<p className={style.blogItem}>{setList}</p>
				<p className={style.setTitle}>Description</p>
				<p className={style.blogItem}>{description}</p>
				<p className={style.blogItem}>{notes}</p>
				<a href={setLink}>
					<p>Listen</p>
				</a>
			</div>
		</div>
	);
}
BlogPost.propTypes = propTypes;

export default withRouter(BlogPost)
