import React from 'react';
import PropTypes from 'prop-types';
import Navbar from './navbar';
import Footer from './footer';
import Routes from './Routes';

const propTypes = {
  loggedIn: PropTypes.bool.isRequired
};

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
      navIsOpen: false
    };
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

	updateDimensions = () => {
	  const w = window;
	  const	d = document;
	  const { documentElement } = d.documentElement;
	  const	body = d.getElementsByTagName('body')[0];
	  const width = w.innerWidth || documentElement.clientWidth || body.clientWidth;

	  if (width > 800) {
	    this.setState({
	      isMobile: false
	    });
	  } else {
	    this.setState({
	      isMobile: true
	    });
	  }
	};

	toggleNav = () => {
	  this.setState({
	    navIsOpen: !this.state.navIsOpen
	  });
	}

	toggleNav2 = () => {
	  if (this.state.navIsOpen) {
	    this.setState({
	      navIsOpen: false
	    });
	  }
	}

	render() {
	  const { isMobile, navIsOpen } = this.state;

	  return (
  <div className="app">
    <Navbar isMobile={ isMobile }
				toggleNav={ this.toggleNav }
				navIsOpen={ navIsOpen }
				toggleNav2={ this.toggleNav2 } />
    <div onClick={ this.toggleNav2 }>
      <Routes loggedIn={ this.props.loggedIn }
			isMobile={ this.state.isMobile } />
    </div>
    <Footer />
  </div>
	  );
	}
}
App.propTypes = propTypes;
