export const GET_BLOGS_REQUEST = 'GET_BLOGS_REQUEST';
export const GET_BLOGS_SUCCESS = 'GET_BLOGS_SUCCESS';
export const GET_BLOGS_FAILURE = 'GET_BLOGS_FAILURE';

export const ADD_BLOG_REQUEST = 'ADD_BLOG_REQUEST';
export const ADD_BLOG_SUCCESS = 'ADD_BLOG_SUCCESS';
export const ADD_BLOG_FAILURE = 'ADD_BLOG_FAILURE';

export const UPDATE_BLOG_REQUEST = 'UPDATE_BLOG_REQUEST';
export const UPDATE_BLOG_SUCCESS = 'UPDATE_BLOG_SUCCESS';
export const UPDATE_BLOG_FAILURE = 'UPDATE_BLOG_FAILURE';

export const DELETE_BLOG_REQUEST = 'DELETE_BLOG_REQUEST';
export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS';
export const DELETE_BLOG_FAILURE = 'DELETE_BLOG_FAILURE';

export const SELECT_BLOG = 'SELECT_BLOG';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT = 'LOGOUT';

export const addBlogRequest = () => (
	{ type: ADD_BLOG_REQUEST }
);

export const addBlogSuccess = (blog) => (
	{ type: ADD_BLOG_SUCCESS, blog }
);

export const addBlogFailure = () => (
	{ type: ADD_BLOG_FAILURE }
);

export const updateBlogRequest = () => (
	{ type: UPDATE_BLOG_REQUEST }
);

export const updateBlogSuccess = (blog) => (
	{ type: UPDATE_BLOG_SUCCESS, blog }
);

export const updateBlogFailure = (err) => (
	{ type: UPDATE_BLOG_FAILURE, err }
);

export const getBlogsRequest = () => (
	{ type: GET_BLOGS_REQUEST }
);

export const getBlogsSuccess = (blogs) => (
	{ type: GET_BLOGS_SUCCESS, blogs }
);

export const getBlogsFailure = () => (
	{ type: GET_BLOGS_FAILURE }
);

export const deleteBlogRequest = () => (
	{ type: DELETE_BLOG_REQUEST }
);

export const deleteBlogSuccess = (blogId) => (
	{ type: DELETE_BLOG_SUCCESS, blogId }
);

export const deleteBlogFailure = (err) => (
	{ type: DELETE_BLOG_FAILURE, err }
);

export const selectBlog = (blogId) => (
	{ type: SELECT_BLOG, blogId }
);

export const loginRequest = () => (
	{ type: LOGIN_REQUEST }
);

export const loginSuccess = (blog) => (
	{ type: LOGIN_SUCCESS, blog }
);

export const loginFailure = () => (
	{ type: LOGIN_FAILURE }
);

export const logoutRequest = () => (
	{ type: LOGOUT }
);
