import React from 'react';
import PropTypes from 'prop-types';
import DetailLineItem from './DetailLineItem';
import style from './epk.module.css';

const propTypes = {
	details: PropTypes.array.isRequired,
	title: PropTypes.string.isRequired
};

const Details = (props) => {
	const details = props.details.map((detail, i) => {
		return <DetailLineItem detail={detail} key={detail._id} index={i} />;
	});

	return (
		<div className="w100 center col">
			<h2 className={style.secHead}>{props.title}</h2>
			<div className={`${style.bigBullet} center`}>
				<ul className={`${style.list} pT30 col`}>{details}</ul>
			</div>
		</div>
	);
}
Details.propTypes = propTypes;

export default Details;
