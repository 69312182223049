import React from 'react';
import style from './epk.module.css';

const DetailLineItem = (props) => {
	const { what, where } = props.detail;
	return (
		<li>
			<strong>{what}</strong>
			<em className={style.hyphon}>-</em>
			<em>{where}</em>
		</li>
	);
}

export default DetailLineItem;
