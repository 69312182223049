import axios from 'axios';
import apiUrl from '../../config';
import {
	updateBlogRequest,
	updateBlogSuccess,
	updateBlogFailure,
	addBlogRequest,
	addBlogSuccess,
	addBlogFailure,
	getBlogsRequest,
	getBlogsSuccess,
	getBlogsFailure,
	deleteBlogRequest,
	deleteBlogSuccess,
	deleteBlogFailure,
	loginSuccess,
	logoutRequest
} from '../actions/blogActions';

const axiosBlog = axios.create({
	baseURL: `${apiUrl}blog`
});

export function getBlogs() {
	return (dispatch) => {
		dispatch(getBlogsRequest());

		return axiosBlog.get('/').then(
			res => dispatch(getBlogsSuccess(res.data)),
			error => dispatch(getBlogsFailure(error))
		);
	};
}

export function addBlog(blog) {
	return (dispatch) => {
		dispatch(addBlogRequest());

		const formData = new FormData();
		formData.append('picture', blog.upload);
		formData.append('blog', JSON.stringify(blog));

		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};
		return axiosBlog.post('/', formData, config).then(
			res => dispatch(addBlogSuccess(res.data)),
			error => dispatch(addBlogFailure(error))
		);
	};
}

export function updateBlog(blog) {
	return (dispatch) => {
		dispatch(updateBlogRequest());

		const formData = new FormData();
		formData.append('picture', blog.upload);
		formData.append('blog', JSON.stringify(blog));
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};

		return axiosBlog.put(`/${blog._id}`, formData, config).then(
			res => dispatch(updateBlogSuccess(res.data)),
			error => dispatch(updateBlogFailure(error))
		);
	};
}

export function deleteBlog(blogId) {
	return (dispatch) => {
		dispatch(deleteBlogRequest());

		axiosBlog.delete(`/${blogId}`).then(
			res => {
				dispatch(deleteBlogSuccess(blogId));
			})
			.catch((error) => {
				dispatch(deleteBlogFailure(error));
			});
	};
}

export function login(success) {
	return (dispatch) => {
		dispatch(loginSuccess(success));
	};
}

export function logout() {
	return (dispatch) => {
		dispatch(logoutRequest());
	};
}
