import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import App from './App';
import loading from '../../assets/images/loading.gif';
import { fetchDetails } from '../../store/thunks/detailThunks';
import { getBlogs } from '../../store/thunks/blogThunks';
import { getEPK } from '../../store/thunks/epkThunks';
import { blogIsFetching } from '../../store/selectors/blogSelectors';

const propTypes = {
	blogs: PropTypes.array.isRequired,
	fetchDetails: PropTypes.func.isRequired,
	getBlogs: PropTypes.func.isRequired,
	getEPK: PropTypes.func.isRequired,
	loggedIn: PropTypes.bool.isRequired,
	blogIsFetching: PropTypes.bool.isRequired
};

class AppContainer extends React.PureComponent {
	componentDidMount() {
		this.props.fetchDetails();
		this.props.getBlogs();
		this.props.getEPK();
	}

	render() {
		const { blogs, loggedIn, blogIsFetching } = this.props;

		let markup = (
			<div className="loadingContainer">
				<img className="loading"
					alt="loading"
					src={loading} />
			</div>
		);

		if (!blogIsFetching) {
			markup = <div><App blogs={blogs}
				loggedIn={loggedIn} />				<ToastContainer />
			</div>;
		}

		return markup;
	}
}
AppContainer.propTypes = propTypes;

function mapStateToProps(state) {
	return {
		blogs: state.blogs.blogs,
		loggedIn: state.blogs.loggedIn,
		blogIsFetching: blogIsFetching(state)
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getBlogs: () => {
			dispatch(getBlogs());
		},
		fetchDetails: () => {
			dispatch(fetchDetails());
		},
		getEPK: () => {
			dispatch(getEPK());
		}
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer));
export { AppContainer as TestableAppContainer };
