import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Home from './Home';
import { selectBlog } from '../../store/actions/blogActions';

const propTypes = {
	blogs: PropTypes.array.isRequired,
	epk: PropTypes.object.isRequired,
	selectBlog: PropTypes.func.isRequired
};

const HomeContainer = props => <Home {...props} />;

function mapStateToProps(state, ownProps) {
	return {
		blogs: state.blogs.blogs || [],
		epk: state.epk.epk || {}
	};
}

function mapDispatchToProps(dispatch) {
	return {
		selectBlog: (blog) => {
			dispatch(selectBlog(blog));
		}
	}
}

HomeContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
export { HomeContainer as TestableHomeContainer };
