import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditBlog from './EditBlog';
import { selectBlog } from '../../store/actions/blogActions';
import { updateBlog } from '../../store/thunks/blogThunks';
import { blogSelector, blogsSelector } from '../../store/selectors/blogSelectors';

const propTypes = {
	blog: PropTypes.object.isRequired,
	loggedIn: PropTypes.bool.isRequired,
	selectBlog: PropTypes.func.isRequired,
	updateBlog: PropTypes.func.isRequired
};

class EditBlogContainer extends React.PureComponent {
	componentDidMount() {
		this.props.selectBlog(window.location.pathname.split('/')[2]);
	}

	render() {
		let markup = '';
		if (this.props.blog !== undefined) {
			markup = <EditBlog {...this.props} />
		}
		return markup;
	}
}

EditBlog.propTypes = propTypes;

function mapStateToProps(state, ownProps) {
	return {
		blogs: blogsSelector(state),
		blog: blogSelector(state),
		loggedIn: state.blogs.loggedIn
	}
}

function mapDispatchToProps(dispatch) {
	return {
		updateBlog: (blog) => {
			dispatch(updateBlog(blog));
		},
		selectBlog: (blogId) => {
			dispatch(selectBlog(blogId));
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBlogContainer);
