import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import style from './navbar.module.css';
import icon from '../../../assets/images/icon4.png';

const propTypes = {
	isMobile: PropTypes.bool.isRequired,
	navIsOpen: PropTypes.bool.isRequired,
	toggleNav: PropTypes.func.isRequired,
	toggleNav2: PropTypes.func.isRequired
};

const Navbar = (props) => {
	const {
		navIsOpen,
		isMobile,
		toggleNav,
		toggleNav2 } = props;

	let navStyle;

	if (!isMobile) {
		navStyle = {
			hamburger: {
				display: 'none'
			},
			fullNav: {
				display: 'flex'
			},
			expandNav: {
				display: 'none'
			},
			icon: {
				background: 'transparent'
			}
		};
	} else {
		if (navIsOpen) {
			navStyle = {
				expandNav: {
					display: 'flex'
				},
				hamburger: {
					display: 'flex',
					alignItems: 'center'
				},
				fullNav: {
					display: 'none'
				},
				icon: {
					background: '#2d004d'
				}
			};
		} else {
			navStyle = {
				hamburger: {
					display: 'flex',
					alignItems: 'center'
				},
				fullNav: {
					display: 'none'
				},
				expandNav: {
					display: 'none'
				},
				icon: {
					background: 'transparent'
				}
			};
		}
	}

	return (
		<nav className="row center nav fix z10">
			<div style={navStyle.hamburger} className="w100 rel row h50">
				<Link to="/">
					<img role="button"
						alt="logo"
						className={style.mobileNavLogo} src={icon}
						onClick={toggleNav2} />
				</Link>
				<div className={`${style.ham} col`} role="button"
					style={navStyle.icon}
					onClick={toggleNav}>
					<span className={style.hamSpan}></span>
					<span className={style.hamSpan}></span>
					<span className={style.hamSpan}></span>
				</div>
			</div>
			<div role="button"
				className={style.dropNav}
				style={navStyle.expandNav}
				onClick={toggleNav}>
				<Link to="/music">
					<div className={style.navLink}>MUSIC</div>
				</Link>
				<Link to="/tour">
					<div className={style.navLink}>TOUR</div>
				</Link>
				<Link to="/media">
					<div className={style.navLink}>MEDIA</div>
				</Link>
				<Link to="/blog">
					<div className={style.navLink}>BLOG</div>
				</Link>
				<Link to="/about">
					<div className={style.navLink}>ABOUT</div>
				</Link>
				<Link to="/contact">
					<div className={style.navLink}>CONTACT</div>
				</Link>
			</div>
			<div className="row center w100"
				style={navStyle.fullNav}>
				<Link to="/music">
					<div className={style.navLink}>MUSIC</div>
				</Link>
				<Link to="/tour">
					<div className={style.navLink}>TOUR</div>
				</Link>
				<Link to="/media">
					<div className={style.navLink}>MEDIA</div>
				</Link>
				<Link to="/">
					<div className={style.navLink}>
						<img className={style.navbarLogo} alt="logo2" src={icon} />
					</div>
				</Link>
				<Link to="/blog">
					<div className={style.navLink}>BLOG</div>
				</Link>
				<Link to="/about">
					<div className={style.navLink}>ABOUT</div>
				</Link>
				<Link to="/contact">
					<div className={style.navLink}>CONTACT</div>
				</Link>
			</div>
		</nav>
	);
};
Navbar.propTypes = propTypes;

export default Navbar;
