import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import BlogPost from '../blog/BlogPost';
import Images from '../../assets/images';
import Videos from '../../assets/videos';
import ContentItem from './ContentItem';
import BookingInfo from '../contact/BookingInfo';
import BandsInTown from '../tour/BandsInTown';

const propTypes = {
	blogs: PropTypes.array.isRequired,
	epk: PropTypes.object.isRequired,
	selectBlog: PropTypes.func.isRequired
};

class Home extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isMobile: false
		};
	}

	componentDidMount() {
		const script = document.createElement('script');
		script.src = 'https://widget.bandsintown.com/main.min.js';
		script.async = true;
		document.body.appendChild(script);
		this.updateDimensions();
	}

	updateDimensions = () => {
		const w = window;
		const d = document;
		const { documentElement } = d.documentElement;
		const body = d.getElementsByTagName('body')[0];
		const width = w.innerWidth || documentElement.clientWidth || body.clientWidth;

		if (width > 800) {
			this.setState({
				isMobile: false
			});
		} else {
			this.setState({
				isMobile: true
			});
		}
	};

	render() {
		const { epk, blogs, selectBlog } = this.props;
		const { isMobile } = this.state;
		let mappedBlogs;
		if (blogs.length > 0) {
			const sortedBlogs = blogs.sort((a, b) => new Date(b.date) - new Date(a.date));

			mappedBlogs = sortedBlogs.map((blog, i) => <BlogPost blog={blog} key={blog._id} selectBlog={selectBlog} />);
		}

		return (
			<div className="home-page">
				<video
					autoPlay={true}
					playsInline={true}
					loop={true} muted={true} className="mainVideo">
					<source src={Videos.bluebirdRecap} type="video/mp4" />
				</video>
				<div className="scAll">
					<iframe className="scAllContent"
						title="soundcloud"
						width="100%" height="450" scrolling="no" frameBorder="no" allowFullScreen="yes" src={epk.album} />
				</div>
				<ContentItem title="TOUR" isMobile={isMobile} image={Images.empire1} />
				<div className="tourHome">
					<BandsInTown />
				</div>
				<ContentItem title="MEDIA" isMobile={isMobile} image={Images.hallo2} />
				<div className="vidHomeContainer">
					<iframe className="videoHome"
						title="video"
						src={epk.firstVideo} frameBorder="0" allowFullScreen={true} />
				</div>
				<ContentItem title="BLOG" isMobile={isMobile} image={Images.blueBirdFull} />
				<div className="homeBlog">
					{mappedBlogs ? mappedBlogs[0] : []}
				</div>
				<ContentItem title="BOOKING" isMobile={isMobile} image={Images.pressPic} />
				<div className="homeBlog">
					<div className="col center">
						<BookingInfo />
						<Link to="/contact">
							<button className="btn btn-home">Contact Us</button>
						</Link>
					</div>
				</div>
			</div>
		);
	}
}
Home.propTypes = propTypes;

export default Home;
