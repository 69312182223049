import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dashboard from './Dashboard';
import { addDetail, reorderDetails } from '../../store/thunks/detailThunks'

const propTypes = {
	details: PropTypes.array.isRequired,
	epk: PropTypes.object.isRequired,
	loggedIn: PropTypes.bool.isRequired,
	addDetail: PropTypes.func.isRequired,
	reorderDetails: PropTypes.func.isRequired
};

const DashboardContainer = props =>
	<Dashboard {...props} />;
DashboardContainer.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
	return {
		addDetail: (detail) => {
			dispatch(addDetail(detail));
		},
		reorderDetails: (details) => {
			dispatch(reorderDetails(details));
		}
	}
}

function mapStateToProps(state) {
	return {
		details: state.details.details || [],
		epk: state.epk.epk || {},
		loggedIn: state.blogs.loggedIn
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
export { DashboardContainer as TestableDashboardContainer };
