import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Epk from './Epk';

const propTypes = {
	details: PropTypes.array.isRequired,
	epk: PropTypes.object.isRequired,
};

const EpkContainer = props =>
	<Epk {...props} />
EpkContainer.propTypes = propTypes;

function mapStateToProps(state) {
	return {
		details: state.details.details || [],
		epk: state.epk.epk || {}
	};
}

export default connect(mapStateToProps, null)(EpkContainer);
