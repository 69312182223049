import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { withRouter } from 'react-router-dom';
import style from './blog.module.css';

const propTypes = {
	loggedIn: PropTypes.bool.isRequired,
	addBlog: PropTypes.func.isRequired
};

class NewBlog extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			setList: '',
			date: '',
			title: '',
			image: '',
			location: '',
			description: '',
			setLink: '',
			upload: null,
			notes: '',
			lineup: ''
		};
	}

	componentDidMount() {
		if (!this.props.loggedIn) {
			this.props.history.push('/login');
		}
	}

	onDrop = (files) => {
		this.setState({
			image: files[0].preview,
			upload: files[0]
		});
	}

	goToBlog = () => {
		this.props.history.push('/blog');
	}

	render() {
		return (
			<main>
				<h2>Blog Form</h2>
				<form>
					<input type="text"
						placeholder="Title"
						onChange={(evt) => { this.setState({ title: evt.target.value }); }} />
					<input type="text"
						placeholder="Location"
						onChange={(evt) => { this.setState({ location: evt.target.value }); }} />
					<input type="date"
						placeholder="Choose a Date"
						value={this.state.date.substring(0, 10)}
						onChange={(evt) => { this.setState({ date: new Date(evt.target.value).toISOString() }); }} />
					<textarea
						placeholder="Set List"
						onChange={(evt) => { this.setState({ setList: evt.target.value }); }} />
					<input type="text"
						placeholder="Set Link"
						onChange={(evt) => { this.setState({ setLink: evt.target.value }); }} />
					<textarea className="input-field textarea"
						placeholder="Set Description"
						onChange={(evt) => { this.setState({ description: evt.target.value }); }} />
					<input type="text"
						placeholder="Set List Notes"
						onChange={(evt) => { this.setState({ notes: evt.target.value }); }} />
					<input type="text"
						placeholder="Lineup"
						onChange={(evt) => { this.setState({ lineup: evt.target.value }); }} />
					<div className={style.panel}>
						<h2>File Uploader</h2>
						<Dropzone onDrop={(files) => { this.onDrop(files); }}>
							<img src={this.state.image}
								alt="upload preview"
								className={`point ${style.preview}`} />
						</Dropzone>
					</div>
					<button onClick={() => {
						this.props.addBlog(this.state);
						this.props.history.push('/dashboard');
					}}
						type="submit"
						value="Submit"
						className="btn btn-contact">Submit
					</button>
				</form>
			</main>
		);
	}
}
NewBlog.propTypes = propTypes;

export default withRouter(NewBlog);
