import backPic from './backPic.jpg';
import blueBirdFamilyPhoto from './blueBirdFamilyPhoto.jpg';
import blueBirdFull from './blueBirdFull.jpg';
import cervantes1 from './cervantes1.jpg';
import cervantes2 from './cervantes2.jpg';
import checkMark from './checkMark.png';
import collidoscopeLogo from './collidoscopeLogo.png';
import empire1 from './empire1.jpg';
import error from './error.png';
import euphoria from './euphoria.jpg';
import exmag from './exmag.jpg';
import funkIsFree from './funkIsFree.jpg';
import hallo1 from './hallo1.jpg';
import hallo2 from './hallo2.jpg';
import hank1 from './hank1.jpg';
import headHills from './headHills.jpg';
import hyperSpace2 from './hyperSpace2.gif';
import icon from './icon.png';
import icon2 from './icon2.png';
import icon4 from './icon4.png';
import info from './info.png';
import jacob1 from './jacob1.jpg';
import jakub from './jakub.jpg';
import james1 from './james1.jpg';
import junoWhat from './junoWhat.jpg';
import krookedDrivers from './krookedDrivers.jpg';
import loading from './loading.gif';
import loading2 from './loading2.gif';
import logo from './logo.png';
import madSquab from './madSquab.jpg';
import madSquab2 from './madSquab2.jpg';
import opiuo from './opiuo.jpg';
import pearls1 from './pearls1.jpg';
import pressPic from './pressPic.jpg';
import spreadTheWord from './spreadTheWordFest.jpg';
import squab1 from './squab1.jpg';
import success from './success.png';
import supervision from './supervision.jpg';
import theEldo from './theEldo.jpg';
import wysidio2 from './wysidio2.png';

const Images = {
	'backPic': backPic,
	'blueBirdFamilyPhoto': blueBirdFamilyPhoto,
	'blueBirdFull': blueBirdFull,
	'cervantes1': cervantes1,
	'cervantes2': cervantes2,
	'checkMark': checkMark,
	'collidoscopeLogo': collidoscopeLogo,
	'empire1': empire1,
	'error': error,
	'euphoria': euphoria,
	'exmag': exmag,
	'funkIsFree': funkIsFree,
	'hallo1': hallo1,
	'hallo2': hallo2,
	'hank1': hank1,
	'headHills': headHills,
	'hyperSpace2': hyperSpace2,
	'icon': icon,
	'icon2': icon2,
	'icon4': icon4,
	'info': info,
	'jacob1': jacob1,
	'jakub': jakub,
	'james1': james1,
	'junoWhat': junoWhat,
	'krookedDrivers': krookedDrivers,
	'loading': loading,
	'loading2': loading2,
	'logo': logo,
	'madSquab': madSquab,
	'madSquab2': madSquab2,
	'opiuo': opiuo,
	'pearls1': pearls1,
	'pressPic': pressPic,
	'spreadTheWord': spreadTheWord,
	'squab1': squab1,
	'success': success,
	'supervision': supervision,
	'theEldo': theEldo,
	'wysidio2': wysidio2
};

export default Images;
