import { combineReducers } from 'redux';
import blogs from './blogReducer';
import epk from './epkReducer';
import details from './detailReducer';

const rootReducer = combineReducers({
  blogs,
  epk,
  details
});

const LeaderBoardApp = (state, action) => rootReducer(state, action);

export default LeaderBoardApp;
