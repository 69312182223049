import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import apiUrl from '../../config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { login } from '../../store/thunks/blogThunks';
import style from './login.module.css';

const propTypes = {
	login: PropTypes.func.isRequired
};

const axiosLogin = axios.create({
	baseURL: `${apiUrl}blog`
});

class Login extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
		};
	}

	submitLogin = (evt) => {
		evt.preventDefault();
		axiosLogin.put('/login', this.state)
			.then((res) => {
				if (res.status === 204) {
					return;
				}
				toast("Success!", {
					position: toast.POSITION.BOTTOM_RIGHT
				});
				this.props.login(this.state);
				this.props.history.push('/dashboard');
			})
			.catch((err) => {
				toast("An error occured", {
					position: toast.POSITION.BOTTOM_RIGHT
				});
			});
	}

	render() {
		return (
			<main className="vh100 center w100 col">
				<h2>Login</h2>
				<div className={style.login}>
					<form onSubmit={this.submitLogin}>
						<div className="row mB20 center">
							<input
								type="email"
								placeholder="Email Address"
								value={this.state.username}
								onChange={(evt) => {
									evt.preventDefault(); this.setState({ email: evt.target.value });
								}}
							/>
						</div>
						<div className="row mB20 center">
							<input
								type="password"
								placeholder="Password"
								onChange={(evt) => {
									evt.preventDefault(); this.setState({ password: evt.target.value });
								}}
							/>
						</div>
						<div className="row mB120 center">
							<button
								type="submit"
								onClick={(evt) => { this.submitLogin(evt); }}
							>
								LOG IN
          </button>
						</div>
					</form>
				</div>
			</main>
		);
	}
}
Login.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
	return {
		login: (credentials) => {
			dispatch(login(credentials));
		}
	};
}

export default withRouter(connect(null, mapDispatchToProps)(Login));
