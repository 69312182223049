import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from 'react-toastify';
import { isEmail } from 'validator';
import BookingInfo from './BookingInfo';
import apiUrl from '../../config';
import style from './bookingInfo.module.css';
import 'react-toastify/dist/ReactToastify.css';

class Contact extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			name: '',
			email: '',
			subject: '',
			message: '',
			expired: true
		};
	}

	handleSubmit = (e) => {
		e.preventDefault();
		const { name, email, expired } = this.state;
		if (name === '') {
			toast("Please include a name", {
				position: toast.POSITION.BOTTOM_RIGHT
			});
			return;
		}
		else if (!isEmail(email.toString())) {
			toast("Please include a valid email", {
				position: toast.POSITION.BOTTOM_RIGHT
			});
			return;
		} else if (expired) {
			toast("Your recaptcha was whack yo", {
				position: toast.POSITION.BOTTOM_RIGHT
			});
			return;
		}

		axios.post(`${apiUrl}email`, this.state)
			.then((res) => {
				toast("Success!", {
					position: toast.POSITION.BOTTOM_RIGHT
				});
			})
			.catch((error) => {
				toast("There was an error", {
					position: toast.POSITION.BOTTOM_RIGHT
				});
				return;
			});
		setTimeout(this.props.history.push('/'), 3000);
	}

	handleCaptcha = (value) => {
		if (value === null) {
			this.setState({ expired: true })
		} else {
			this.setState({ expired: false });
		}
	}

	render() {
		return (
			<main>
				<h2>Contact Us!</h2>
				<BookingInfo />
				<form onSubmit={this.handleSubmit}
					className="center">
					<input type="text"
						placeholder="Your Name"
						onChange={(evt) => { this.setState({ name: evt.target.value }); }} />
					<input type="text"
						placeholder="Your Email"
						onChange={(evt) => { this.setState({ email: evt.target.value }); }} />
					<input type="text"
						placeholder="Subject"
						onChange={(evt) => { this.setState({ subject: evt.target.value }); }} />
					<textarea placeholder="Your Message"
						className={style.textArea}
						onChange={(evt) => { this.setState({ message: evt.target.value }); }} />
					<ReCAPTCHA
						sitekey="6LeQMIsUAAAAALi-OhEbxi6mgFbLLhSm17nf8sL3"
						onChange={this.handleCaptcha}
					/>
					<button type="submit"
						value="Submit"
						className={`${style.contactButton} btn btn-contact`}>Submit</button>
				</form>
			</main>
		);
	}
}

export default withRouter(Contact);
