import axios from 'axios';
import apiUrl from '../../config';
import {
	getDetailsRequest,
	getDetailsSuccess,
	getDetailsFailure,
	addDetailRequest,
	addDetailSuccess,
	addDetailFailure,
	updateDetailRequest,
	updateDetailSuccess,
	updateDetailFailure,
	reorderDetailsRequest,
	reorderDetailsSuccess,
	reorderDetailsFailure,
	deleteDetailRequest,
	deleteDetailSuccess,
	deleteDetailFailure
} from '../actions/detailActions';

const axiosDetail = axios.create({
	baseURL: `${apiUrl}detail`
});

export function addDetail(detail) {
	return (dispatch) => {
		dispatch(addDetailRequest());

		return axiosDetail.post('/', detail).then(
			res => dispatch(addDetailSuccess(res.data)),
			error => dispatch(addDetailFailure(error))
		);
	};
}

export function fetchDetails() {
	return (dispatch) => {
		dispatch(getDetailsRequest());

		return axiosDetail.get('/').then(
			res => dispatch(getDetailsSuccess(res.data)),
			error => dispatch(getDetailsFailure(error))
		);
	};
}

export function updateDetail(detail) {
	return (dispatch) => {
		dispatch(updateDetailRequest());

		return axiosDetail.put('/', detail).then(
			res => dispatch(updateDetailSuccess(detail)),
			error => dispatch(updateDetailFailure(error))
		);
	};
}

export function reorderDetails(details) {
	return (dispatch) => {
		dispatch(reorderDetailsRequest());
		return axiosDetail.put('/reorder', details).then(
			() => dispatch(reorderDetailsSuccess()),
			error => dispatch(reorderDetailsFailure(error))
		);
	};
}

export function deleteDetail(detailId) {
	return (dispatch) => {
		dispatch(deleteDetailRequest());
		return axiosDetail.delete(`/${detailId}`).then(
			() => dispatch(deleteDetailSuccess(detailId)),
			error => dispatch(deleteDetailFailure(error))
		);
	};
}
