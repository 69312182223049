import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Images from '../../assets/images';
import style from './about.module.css';

const propTypes = {
	epk: PropTypes.object.isRequired
};

const About = (props) => {
	const { bio } = props.epk;
	return (
		<main>
			<h2>About</h2>
			<section className="mLA">
				<img className={style.familyPhoto}
					alt="press"
					src={Images.blueBirdFamilyPhoto} />
				<p className={style.bioText}>{bio}</p>
			</section>
		</main>
	);
};
About.propTypes = propTypes;

function mapStateToProps(state) {
	return {
		epk: state.epk.epk || {}
	};
}

export default connect(
	mapStateToProps,
	null
)(About);
