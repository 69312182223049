const blogIdSelector = state => state.blogs.selectedBlogId;

const blogsSelector = state => state.blogs.blogs;

const blogSelector = state => {
	const selectedBlog = state.blogs.blogs.find((blog) => blog._id === state.blogs.selectedBlogId);
	return selectedBlog;
}

const blogIsFetching = (state) => state.blogs.isFetching;

export {
	blogIdSelector,
	blogsSelector,
	blogSelector,
	blogIsFetching
};
