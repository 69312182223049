import axios from 'axios';
import apiUrl from '../../config';
import {
	getEpkRequest,
	getEpkSuccess,
	getEpkFailure,
	updateEpkRequest,
	updateEpkSuccess,
	updateEpkFailure
} from '../actions/epkActions';

const axiosEPK = axios.create({
	baseURL: `${apiUrl}epk`
});

export function getEPK() {
	return (dispatch) => {
		dispatch(getEpkRequest());

		return axiosEPK.get('/').then(
			res => dispatch(getEpkSuccess(res.data)),
			error => dispatch(getEpkFailure(error))
		);
	};
}

export function updateEPK(epk) {
	return (dispatch) => {
		dispatch(updateEpkRequest());
		return axiosEPK.put(`/${epk._id}`, epk).then(
			res => dispatch(updateEpkSuccess(res.data)),
			error => dispatch(updateEpkFailure(error))
		);
	};
}
