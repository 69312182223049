import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Posts from './Posts';
import { selectBlog } from '../../store/actions/blogActions';
import { deleteBlog } from '../../store/thunks/blogThunks';

const propTypes = {
	blogs: PropTypes.array.isRequired,
	selectBlog: PropTypes.func.isRequired,
	deleteBlog: PropTypes.func.isRequired
};

const PostsContainer = (props) => <Posts {...props} />
PostsContainer.propTypes = propTypes;

function mapStateToProps(state) {
	return {
		blogs: state.blogs.blogs || []
	};
}

function mapDispatchToProps(dispatch) {
	return {
		selectBlog: (blogId) => {
			dispatch(selectBlog(blogId));
		},
		deleteBlog: (blogId) => {
			dispatch(deleteBlog(blogId));
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PostsContainer)
