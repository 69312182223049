import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import DetailLineItem from './DetailLineItem';

const propTypes = {
	details: PropTypes.array.isRequired,
	title: PropTypes.string.isRequired
};

const Details = (props) => {
	const { title } = props;
	const mappedDetails = props.details.map((detail, i) => <Draggable
		key={`detail-${detail._id}`}
		draggableId={detail._id} index={i}>
		{(provided, snapshot) => (
			<div
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
			><DetailLineItem detail={detail} key={detail._id} index={i} /></div>
		)}
	</Draggable>);

	return (
		<div className="w100 detailBox center col">
			<h4>{title}</h4>
			<div className="col w100">{mappedDetails}</div>
		</div>
	);
}
Details.propTypes = propTypes;

export default Details;

