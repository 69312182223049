import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NewBlog from './NewBlog';
import { addBlog } from '../../store/thunks/blogThunks';

const propTypes = {
	loggedIn: PropTypes.bool.isRequired,
	addBlog: PropTypes.func.isRequired
};

const NewBlogContainer = props =>
	<NewBlog {...props} />
NewBlogContainer.propTypes = propTypes;

function mapStateToProps(state) {
	return {
		loggedIn: state.blogs.loggedIn
	};
}

function mapDispatchToProps(dispatch) {
	return {
		addBlog: (blog) => {
			dispatch(addBlog(blog));
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(NewBlogContainer);
