import React from 'react';
import BandsInTown from './BandsInTown';

const Tour = () => (
	<main>
		<h2>Tour</h2>
		<BandsInTown />
	</main>
);

export default Tour;
