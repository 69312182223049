import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteDetail, updateDetail } from '../../store/thunks/detailThunks'
import detailStyle from './detail.module.css';

const propTypes = {
	detail: PropTypes.object.isRequired,
	deleteDetail: PropTypes.func.isRequired,
	updateDetail: PropTypes.func.isRequired
};

class DetailLineItem extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			values: {
				what: props.detail.what,
				where: props.detail.where,
				type: props.detail.type,
			},
			style: {
				edit: {
					display: 'none'
				},
				delete: {
					display: 'flex'
				},
				input: {
					display: 'none'
				},
				div: {
					display: 'flex'
				}
			},
			editing: false,
		}
	}

	toggleOps = () => {
		if (!this.state.editing) {
			this.setState({
				style: {
					edit: {
						display: 'flex'
					},
					delete: {
						display: 'none'
					},
					input: {
						display: 'flex'
					},
					div: {
						display: 'none'
					}
				},
				editing: true
			})
		}
		else {
			this.setState({
				style: {
					edit: {
						display: 'none'
					},
					delete: {
						display: 'flex'
					},
					input: {
						display: 'none'
					},
					div: {
						display: 'flex'
					}
				},
				editing: false
			})
		}
	}

	updateDetail = () => {
		const data = {
			_id: this.props.detail._id,
			what: this.state.values.what,
			where: this.state.values.where,
			type: this.state.values.type,
			index: this.props.detail.index
		}
		this.props.updateDetail(data);
	}

	render() {
		const {
			what,
			where
		} = this.props.detail;

		return (
			<div className="main w100 row detailLI">
				<div
					style={this.state.style.div} className={`${detailStyle.detailWhat} detailContainer`}>
					<div className="detail">{what}</div>
					<div className="detail">{where}</div>
				</div>
				<div style={this.state.style.input} className="detailContainer">
					<input
						type='text'
						className="input"
						defaultValue={what}
						onChange={(evt) => {
							this.setState({
								values: {
									what: evt.target.value,
									where: this.state.values.where,
									type: this.state.values.type,
								}
							})
						}}
					/>
					<input
						type='text'
						className="input"
						defaultValue={where}
						onChange={(evt) => {
							this.setState({
								values: {
									what: this.state.values.what,
									where: evt.target.value,
									type: this.state.values.type,
								}
							})
						}}
					/>
				</div>
				<div className="mLA row" style={this.state.style.delete}>
					<div className="penBox center point"
						onClick={() => {
							this.toggleOps();
						}}
					>
						<i className="fa fa-pencil" aria-hidden="true"></i>
					</div>
					<div className="penBox center point"
						onClick={() => {
							this.props.deleteDetail(this.props.detail._id);
						}}
					>
						<i className="fa fa-minus-square" aria-hidden="true"></i>
					</div>
				</div>
				<div className="mLA row" style={this.state.style.edit}>
					<div className="penBox center point"
						onClick={() => {
							this.updateDetail();
							this.toggleOps();
						}}
					>
						<i className="fa fa-telegram" aria-hidden="true"></i>
					</div>
					<div className="penBox center point"
						onClick={() => {
							this.toggleOps();
						}}
					>
						<i className="fa fa-window-close" aria-hidden="true"></i>
					</div>
				</div>
			</div>
		);
	}
}
DetailLineItem.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
	return {
		deleteDetail: (data) => {
			dispatch(deleteDetail(data));
		},
		updateDetail: (data) => {
			dispatch(updateDetail(data));
		}
	}
}

export default connect(null, mapDispatchToProps)(DetailLineItem);
