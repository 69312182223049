import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import style from './blog.module.css';

const propTypes = {
	blog: PropTypes.object.isRequired,
	selectBlog: PropTypes.func.isRequired,
	deleteBlog: PropTypes.func.isRequired
};

const BlogItem = (props) => {
	const { selectBlog, deleteBlog, blog } = props;
	return (
		<div className={style.lineItem}>
			<div onClick={() => {
				selectBlog(blog._id);
				props.history.push(`/blog/:${blog._id}`);
			}}
				className={`${style.blogLineItemName} row w100`}>
				<div className={`${style.detail} center`}>{blog.title}</div>
				<div className={`${style.date} center`}>{moment(blog.date).format('MM DD YYYY')}</div>
			</div>
			<div className="mLA row">
				<div className="penBox center"
					onClick={() => {
						selectBlog(blog);
						props.history.push(`/edit/${blog._id}`);
					}}>
					<i className="fa fa-pencil" aria-hidden="true" />
				</div>
				<div className="penBox center point"
					onClick={() => {
						deleteBlog(blog._id);
					}}>
					<i className="fa fa-minus-square" aria-hidden="true" />
				</div>
			</div>
		</div>
	);
}
BlogItem.propTypes = propTypes;

export default withRouter(BlogItem)
