import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Details from './Details';
import Images from '../../assets/images';
import style from './epk.module.css';

const propTypes = {
	epk: PropTypes.object.isRequired,
	details: PropTypes.array.isRequired
};

const Epk = (props) => {
	const {
		bio,
		album,
		firstSong,
		secondSong,
		thirdSong,
		firstVideo,
		secondVideo
	} = props.epk;

	const { details } = props;

	let notableDetails;
	let headlineDetails;
	let festivalDetails;

	if (details.length > 0) {
		const sortedNotableDetails = props.details.filter((detail) => {
			return (detail.type === 'notable');
		}).sort((a, b) => a.index - b.index);

		const sortedHeadlineDetails = props.details.filter((detail) => {
			return (detail.type === 'headline');
		}).sort((a, b) => a.index - b.index);

		const sortedFestivalDetails = props.details.filter((detail) => {
			return (detail.type === 'festival');
		}).sort((a, b) => a.index - b.index);

		notableDetails = <Details title={'Notable Performances'} details={sortedNotableDetails} />;

		headlineDetails = <Details title={'Headline Performances'} details={sortedHeadlineDetails} />;

		festivalDetails = <Details title={'Festival Appearances'} details={sortedFestivalDetails} />;
	}

	const epkStyle = {
		backgroundImage: `url(${Images.blueBirdFull})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundAttachment: 'fixed',
		'margin': 0,
		width: '100%',
		height: '100%'
	};

	return (
		<div style={epkStyle}>
			<div id={style.darkDiv}>
				<div id={style.logoHeader} className="center">
					<img id={style.logoImg} alt="logo"
						className={style.mSA}
						src={Images.logo} />
				</div>
				<div className={style.mainEpk}>
					<div className={`${style.title} center`}>
						<h3 id={style.epkTitle}>ELECTRONIC PRESS KIT</h3>
					</div>
					<div className={`${style.press} ${style.mSA}`}>
						<img className={`${style.pressPic} ${style.mSA}`}
							alt="pressPhoto"
							src={Images.blueBirdFamilyPhoto}
						/>
					</div>
					<div className="col w100">
						<div className={style.section}>
							{notableDetails}
							<div className={`${style.rowCol} ${style.pT30}`}>
								<iframe className={`${style.song1} ${style.mSA} ${style.pT50Col}`}
									title="firstSong"
									src={firstSong}
									frameBorder="0"
									allowFullScreen={true} />
								<iframe className={`${style.song1} ${style.mSA}`}
									title="secondSong"
									src={secondSong}
									frameBorder="0"
									allowFullScreen={true} />
							</div>
						</div>
						<div className={style.section}>
							{headlineDetails}
							<div className={`${style.rowCol} ${style.pT30}`}>
								<iframe className={`${style.song1} ${style.mSA} ${style.pT50Col}`}
									title="thirdSong"
									src={thirdSong}
									frameBorder="0"
									allowFullScreen={true} />
								<iframe className={`${style.song1} ${style.mSA}`}
									title="firstVideo"
									src={firstVideo}
									frameBorder="0"
									allowFullScreen={true} />
							</div>
						</div>
						<div className={style.section}>
							{festivalDetails}
						</div>
						<div className={`${style.section} center`}>
							<iframe src={album}
								className={style.album}
								title="ablum"
								frameBorder="0" />
						</div>
						<div className={`${style.section} col center`}>
							<h2 className={style.secHead}>BIOGRAPHY</h2>
							<p className={`${style.bioContent}`}>
								{bio}
							</p>
						</div>
					</div>
					<div className={`${style.section} center`}>
						<iframe src={secondVideo}
							className={style.album}
							frameBorder="0"
							title="secondVideo" />
					</div>
					<div className={`${style.section} center`}>
						<a className={style.pressKitDl} href="../../CollidoscopePressKit.zip">
							<button className={`${style.download} ${style.buttonText} ${style.hvrGrow}`}>DOWNLOAD PRESS KIT
                </button>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
Epk.propTypes = propTypes;

export default withRouter(Epk);
